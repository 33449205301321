<template>
  <HelloWorld msg/>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import HelloWorld from '../components/HelloWorld.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    HelloWorld,
  },
  data() {
    return {
      msg: "Hello"
    }
  },
});
</script>
